import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { UX2, utils as CoreUtils } from '@wsb/guac-widget-core';
import { TABLET, MOBILE } from '../../common/constants/device';
import {
  dataAids,
  getGalleryDataAid,
  getGalleryCellDataAid,
  getGalleryDataRouteProps
} from '../../common/constants/dataAids';
import wrapWithDeviceDetection from '../../common/wrapWithDeviceDetection';
import Masonry from '@wsb/guac-widget-shared/lib/components/Masonry';
import RenderLightbox from '../../common/renderLightbox';
import GalleryImage from '../../common/components/GalleryImage';

const ImageMaxWidth = {
  [MOBILE]: 182,
  [TABLET]: 370
};
const {
  postRender: { Actions: postRenderActions, monitor }
} = CoreUtils;

export default wrapWithDeviceDetection(
  class Gallery4 extends Component {
    constructor(props) {
      super(...arguments);
      this.state = {
        showLightbox: false,
        selectedIndex: 0,
        page: 1,
        loading: props.imageCount > 2 && !props.renderAsThumbnail
      };
      this.hideLightbox = this.hideLightbox.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.loadMore = this.loadMore.bind(this);
      this.hasNextPage = this.hasNextPage.bind(this);
      this.containerId = uniqueId('gallery4-');
    }

    static get propTypes() {
      return {
        id: PropTypes.string,
        category: PropTypes.string,
        device: PropTypes.string,
        galleryImages: PropTypes.array.isRequired,
        renderAsThumbnail: PropTypes.bool,
        renderMode: PropTypes.string,
        section: PropTypes.string,
        size: PropTypes.string,
        staticContent: PropTypes.object,
        imageCount: PropTypes.number,
        enableImageDimension: PropTypes.bool
      };
    }

    static get defaultProps() {
      return {
        category: 'neutral',
        section: 'default'
      };
    }

    componentDidMount() {
      const { id } = this.props;
      monitor(id, postRenderActions.SCROLL_TO_URL_FRAGMENT);
    }

    hideLightbox() {
      this.setState({
        showLightbox: false
      });
    }

    handleClick(index) {
      this.setState({
        showLightbox: true,
        selectedIndex: index
      });
    }

    loadMore() {
      this.setState({ page: this.state.page + 1 });
    }

    renderSeeMoreText() {
      const { staticContent } = this.props;
      const styles = { paddingTop: 'xlarge', textAlign: 'center' };

      return (
        <UX2.Element.Block style={ styles }>
          <UX2.Element.MoreLink.Expand
            role='button'
            tabIndex='0'
            onClick={ this.loadMore }
            data-aid={ dataAids.GALLERY_MORE_BUTTON }
            children={ staticContent.showMore }
            data-edit-interactive={ true }
          />
        </UX2.Element.Block>
      );
    }

    hasNextPage() {
      return !!this.props.galleryImages[this.state.page];
    }

    renderContent(imageData, imagesLen, externalLink) {
      const { device = TABLET, enableImageDimension } = this.props;
      const { loading } = this.state;
      const imageWidth = ImageMaxWidth[device];
      const containerStyles = {
        padding: 'xxsmall',
        width: '50%',
        display: 'inline-block',
        ['@md']: {
          width: '33.3333%'
        }
      };
      const imageProps = {
        'imageData': {
          ...imageData,
          outputWidth: imageWidth,
          enableImageDimension
        },
        'onClick': !externalLink
          ? () => {
            this.handleClick(imageData.index);
          }
          : null,
        'data-aid': getGalleryDataAid(imageData.index),
        'style': {
          cursor: 'pointer',
          height: 'auto',
          width: '100%',
          opacity: loading ? 0 : 1,
          transition: 'opacity 1s',
          display: 'block'
        },
        ...getGalleryDataRouteProps(imageData.index, { isImage: true }),
        'isFullWidthMobile': false
      };

      return (
        <UX2.Element.Block
          style={ containerStyles }
          data-aid={ getGalleryCellDataAid(imageData.index) }
        >
          <GalleryImage imageProps={ imageProps } externalLink={ externalLink } background={ false } />
        </UX2.Element.Block>
      );
    }

    renderFakeContent(image) {
      const containerStyles = {
        padding: 'xxsmall',
        width: '100%',
        display: 'inline-block',
        margin: '0.25em'
      };

      return (
        <UX2.Element.Container style={ containerStyles }>
          <UX2.Element.Image
            data-aid={ dataAids.FAKE_THUMBNAIL_NAV_LIST }
            style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
            imageData={ image }
          />
        </UX2.Element.Container>
      );
    }

    renderFakeMasonry(images) {
      const containerStyles = {
        '-moz-column-width': '22em',
        '-webkit-column-width': '22em',
        '-moz-column-gap': '1em',
        '-webkit-column-gap': '1em'
      };

      return <UX2.Element.Container style={ containerStyles }>{ images }</UX2.Element.Container>;
    }

    renderMasonry(images) {
      const { device, renderMode, imageCount } = this.props;
      const content =
        imageCount > 2 ? (
          <Masonry
            device={ device }
            renderMode={ renderMode }
            container={ `#${this.containerId}` }
            onLoad={ () => {
              this.setState({ loading: false });
            } }
          >
            { images }
          </Masonry>
        ) : (
          images
        );
      const containerStyle =
        imageCount > 2
          ? {
            margin: 0,
            // Hack to work around chromium bug https://bugs.chromium.org/p/chromium/issues/detail?id=1234903#c3
            width: 'calc(100% - 0.5px) !important'
          }
          : {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          };

      return (
        <UX2.Element.Block id={ this.containerId } style={ containerStyle }>
          { content }
        </UX2.Element.Block>
      );
    }

    render() {
      const { device, galleryImages, renderAsThumbnail, size } = this.props;
      const renderFakeThumbnail = (device === 'mobile' || size === 'xs') && renderAsThumbnail;

      const allImages = galleryImages.slice(0, this.state.page).map(images => {
        return images.map(({ image, externalLink }) =>
          renderFakeThumbnail
            ? this.renderFakeContent(image)
            : this.renderContent(image, images.length, externalLink)
        );
      });

      const masonryComponent = renderFakeThumbnail
        ? this.renderFakeMasonry(allImages)
        : this.renderMasonry(allImages);

      return (
        <UX2.Element.Block style={{ marginHorizontal: '-xxsmall' }}>
          { masonryComponent }

          { RenderLightbox.call(this) }

          { this.hasNextPage() ? this.renderSeeMoreText() : null }
        </UX2.Element.Block>
      );
    }
  }
);
